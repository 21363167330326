"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const auth_1 = require("./auth/auth");
const dashboard_1 = require("./pages/dashboard");
require("./utils/nav");
require("./modals/stockInvestmentModal");
require("./modals/withdrawModal");
require("./modals/addSub");
require("./modals/addDepositMethod");
const machines_1 = require("./pages/machines");
const admin_dashboard_1 = require("./pages/admin-dashboard");
const subscriptions_1 = require("./pages/subscriptions");
const users_1 = require("./pages/users");
const home_1 = require("./pages/home");
const withdrawals_1 = require("./pages/withdrawals");
(0, auth_1.registerRequest)();
(0, auth_1.loginRequest)();
(0, dashboard_1.showAccountBalance)();
document.addEventListener("DOMContentLoaded", dashboard_1.fetchInvestments);
document.addEventListener("DOMContentLoaded", dashboard_1.fetchWithdrawalRequests);
document.addEventListener("DOMContentLoaded", machines_1.displayMachine);
document.addEventListener("DOMContentLoaded", admin_dashboard_1.getAllDeposits);
document.addEventListener("DOMContentLoaded", admin_dashboard_1.displayData);
document.addEventListener("DOMContentLoaded", subscriptions_1.showAllStocks);
document.addEventListener("DOMContentLoaded", subscriptions_1.showAllMachines);
document.addEventListener("DOMContentLoaded", users_1.showAllUsers);
document.addEventListener("DOMContentLoaded", withdrawals_1.showAllWithdrawals);
document.addEventListener("DOMContentLoaded", home_1.displayMachinesHomePage);
document.addEventListener("DOMContentLoaded", home_1.calculateStockROI);
